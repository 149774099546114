<template>
  <DefaultLayout>
    <template slot="toolbar">
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title class="pl-1">{{ agenda.title }}</v-toolbar-title>
    </template>
    <v-container>
      <div v-if="imgCover != ''">
        <v-card
          v-if="imgCover != '' && showImg == true"
          elevation="0"
          class="d-flex align-center justify-center mb-2"
        >
          <v-img
            :src="require('@/assets/img/agenda2.jpg')"
            :lazy-src="require('@/assets/img/agenda2.jpg')"
            max-height="200px"
            width="100%"
            position="top"
            class="mx-auto"
            aspect-ratio="1"
            cover
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </div>
      <h1>
        {{ agenda.title }}
      </h1>
      <div class="text-subtitle ml-4 mb-2">
        <v-icon color="blue">mdi-calendar-clock</v-icon>
        {{ agenda.marked_date }}
      </div>
      <v-divider></v-divider>
      <div class="pa-4" v-html="agenda.body"></div>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "AgendaInfo",
  components: { DefaultLayout },

  data: () => ({
    agenda: {},
  }),

  computed: {
    showImg() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
  },

  methods: {
    async getInfo() {
      this.$store.state.showPageOverlay = true;
      await this.$axios
        .get("agenda/info", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.$store.state.showPageOverlay = false;
          if (res.data.status == "success") {
            this.agenda = res.data.data;
          } else {
            this.agenda = [];
          }
        });
    },
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.getInfo();
    document.title = "Agenda Info";
  },
};
</script>